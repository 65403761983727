import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { GatsbyImage } from "gatsby-plugin-image"

import "../css/blogs.css"
import "../css/globals.css"
import { Link } from "gatsby"
import TestimonialNew from "../components/testimonialNew"
import ContactFormTata from "../components/formTataSmall"
import useIsMobile from "../hooks/useIsMobile"

export default function Blogs(props){ 
  const isMobile = useIsMobile('1024px')
  const data = props.pageContext.content;

  useEffect(() => {
    let scrollListner;
    if(!isMobile)
        scrollListner = window.addEventListener("scroll", handleScroll);
  
    return () => {
        if(!isMobile)
            window.removeEventListener("scroll", scrollListner);
    }
  }, [])

  const handleScroll = () => {
    let el = document.getElementById("sticky")

    if(document.getElementById("footer") && el){
        let footerTop = document.getElementById("footer").offsetTop;

        let nBottom = el.previousSibling.offsetHeight + el.previousSibling.offsetTop

        let windowTop = window.scrollY
    
        let foot = document.getElementById("footer").offsetHeight
        let x = footerTop - foot - el.offsetHeight/2;
    
        if(windowTop > nBottom && windowTop < x){
          el.style.position = "fixed";
          el.style.width = `23%`;
          el.style.top = "19vh";
        }
        else{
          el.style.position = "sticky";
          el.style.width = "100%";
        }
    }
  }

  let topStoriesArr = [
    {
      heading:
        "The Digitization of Education and What It Spells for Your Child",
      link: "digitized-education",
    },
    {
      heading:
        "Why Parents Involvement in Students Online Learning is Important",
      link: "parents-online-learning",
    },
    {
      heading: "The Art of Interleaving and How it Benefits your child",
      link: "art-of-interleaving",
    },
    {
      heading:
        "Spaced Practices and How they Teach your Child to Learning Effectively",
      link: "spaced-practices",
    },
  ]

  return (
    <Layout>
      {data.field_blog_schema_script && (
        <Helmet>
          <script type="application/ld+json">
            {`
              ${data.field_blog_schema_script}
        `}
          </script>
        </Helmet>
      )}

      <SEO
        title={data.field_meta_title}
        description={data.field_meta_desc}
        lang="en"
      />
      <div className="container-fluid blog-wrapper">
        <div className="row">
          <div className="col-lg-9 left-content">
            <div className="col-12">
              <div className="blog-heading font-samsung">{data.title}</div>
              <br />
              <div className="blog-date font-samsung color-blue">
                {data.field_blog_date}
              </div>
              <div className="blog-banner text-center mx-auto">
                <GatsbyImage
                  image={
                    props.pageContext.content.relationships
                      .field_blog_banner_image.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  loading="lazy"
                  placeholder="tracedSVG"
                  formats={["webp"]}
                />
              </div>
              <div className="blog-content font-lato mt-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.field_content_intro.processed,
                  }}
                />
              </div>
              {/* <div className="blog-images">
                <div className="row">
                  {data.relationships.field_blog_images.map((item, i) => {
                    return (
                      <div className="col-md-3 content-image">
                        <GatsbyImage
                          image={
                            item.localFile.childrenImageSharp[0]
                              .gatsbyImageData
                          }
                          loading="lazy"
                          placeholder="tracedSVG"
                          formats={["webp"]}
                        />
                      </div>
                    )
                  })}
                </div>
              </div> */}

              <div className="blog-content font-lato">
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.field_content_end.processed,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-3 right-content" id="sidebar">
            <div id="blog-page-sidebar">
              <div className="col-12">
                <div className="right-content-heading font-samsung mb-2">
                  Top Stories
                </div>
                <div className="top-stories-list">
                  {topStoriesArr.map((item, i) => {
                    return (
                      <div className="top-story" key={i}>
                        <Link to={`/resources/blogs/${item.link}`}>
                          <div className="color-black font-lato">
                            <i className="fa fa-caret-right" /> {item.heading}{" "}
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-12">
                <div className="right-content-heading font-samsung mt-4 mb-2">
                  Testimonials
                </div>
                <TestimonialNew slideCount={1} maxWidth="100%" />
              </div>
              <div className="col-xs-12 col-md-8 col-lg-12 mx-auto" id="sticky">
                <ContactFormTata />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
