import React,{useState,useEffect} from "react"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import "../css/testimonial.css"
import PropTypes from "prop-types"
import HomepageAlert from "../components/HomepageAlert"
import useIsMobile from "../hooks/useIsMobile"

let propTypes = {
  data: PropTypes.string.isRequired,
}

export default function TestimonialNew(props) {

  const drupalData = useStaticQuery(graphql` 
    query testimonials {
      allNodeTestimonials {
        nodes {
          body {
            processed
          }
          title
          field_testimonial_body

          field_testimonials_image {
            alt
          }
          relationships {
            field_testimonials_image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = drupalData.allNodeTestimonials.nodes
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: props.slideCount,
    slidesToScroll: 1,
    ease: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          {" "}
          <i class="fa fa-chevron-right color-black" aria-hidden="true"></i>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          {" "}
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </div>
      </div>
    ),

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  return (
    <div>
      <div id="testimonials" className="container mb-5">
        <Slider {...settings}>
          {data.map((item, i) => {
            return (
              <div key={i} className="mb-2">
                <div className="testimonial">
                  <div className="test-details" style={{maxWidth: props.maxWidth}}>
                    <div className="test-image" style={{ maxWidth: "50px" }}>
                      <GatsbyImage
                        image={
                          item.relationships.field_testimonials_image.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt="Testimonial"
                        title="Testimonial"
                        loading="lazy"
                        placeholder="tracedSVG"
                        quality="10"
                        formats={["webp"]}
                      />
                    </div>
                    <div className="test-heading font-samsung">
                      <p>{item.title}</p>
                    </div>

                    <div
                      className="test-message font-lato"
                      // dangerouslySetInnerHTML={{
                      //   __html: item.body.processed,
                      // }}
                    >
                      {item.field_testimonial_body}
                      {/* {item.body.processed} */}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}
